import React, { useState, ReactElement } from "react"
import SEO from "../../components/SEO"
import T from "@mui/material/Typography"
import Alert from "@mui/material/Alert"
import SetPasswordForm from "../../components/views/SetPasswordForm"
import { useUser } from "../../providers/UserProvider"

const ChangePasswordPage = (): ReactElement => {
  const { updatePassword } = ChangePasswordPage.useUser()
  const [passwordUpdated, setPasswordUpdated] = useState(false)

  const handleUpdatePassword = async (password: string) => {
    setPasswordUpdated(false)
    await updatePassword(password)
    setPasswordUpdated(true)
  }

  return (
    <>
      <ChangePasswordPage.SEO title="Change your password" />
      <T variant="h1" component="h1">
        Change your password
      </T>
      {passwordUpdated ? (
        <Alert data-test-alert="password-change-success" severity="success">
          <T variant="body1" component="div">
            Your password has been updated.
          </T>
        </Alert>
      ) : (
        <></>
      )}
      <SetPasswordForm handleChangePassword={handleUpdatePassword} />
    </>
  )
}

// Setting these as static members allows us to mock during component testing
ChangePasswordPage.useUser = useUser
ChangePasswordPage.SEO = SEO

export default ChangePasswordPage
